<template>
  <div class="requirement-list">
    <h4 class="mb-0">Filled requirements</h4>
    <hr class="mt-2">
    <div  v-for="(requirement, rIndex) of requirements" :key="rIndex" class="mb-4 pl-3 pl-lg-4 position-relative">
      <span style="left:0;" class="position-absolute">{{rIndex+1}}</span>
      <div v-if="['task_input','task_select','task_textarea'].includes(requirement.type)">
          <h5 class="mb-1">{{requirement.title}}</h5>
          <p>{{requirement.answer}}</p>
      </div>
      <div v-else-if="requirement.type == 'photo_gallery'" class="">
          <h5 class="mb-1">{{requirement.title}}</h5>
          <div class="row mb-3">
              <div v-for="(photoType, ptIndex) of requirement.answer" :key="ptIndex"
              class="col-md-4 col-6 text-center">
                  <photo-gallery-card :photoType="photoType"/>
              </div>
          </div>
      </div>
      <div v-else-if="requirement.type == 'color_gallery'" class="" >
          <h5 class="mb-1">{{requirement.title}}</h5>
          <div class="row mb-3">
              <div v-for="(colorType, clIndex) in requirement.answer" :key="clIndex"
              class="col-md-4 col-6 text-center">
                  <color-gallery-card :colorType="colorType"/>
              </div>
          </div>
      </div>
      <div v-else-if="requirement.type == 'task_checkbox'" class="" >
          <h6 class="mb-1">{{requirement.title}}</h6>
          <div v-if="requirement.answer">
              {{requirement.answer.map((i)=> i.name).join(", ")}}
          </div>
      </div>
      <div v-else-if="requirement.type == 'file_uploader'" class="" >
          <h6 class="mb-1">{{requirement.title}}</h6>
          <order-attachments columnClass="col-lg-4 mb-3" v-if="requirement.answer && requirement.answer.length"
                          :files="requirement.answer" />
      </div>
      <div v-else>
          <h5 class="mb-1">{{requirement.title}}</h5>
          <p>{{requirement.answer ? requirement.answer : "none" }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ColorGalleryCard from "@/components/cards/ColorGalleryCard.vue"
import PhotoGalleryCard from "@/components/cards/PhotoGalleryCard.vue"
import OrderAttachments from './OrderAttachments.vue'

export default {
    components:{
      ColorGalleryCard,
      PhotoGalleryCard,
      OrderAttachments,
    },
    computed: {
      order(){
        return this.$store.state.orderList.order
      },
      requirements(){
        return this.order.cart.requirements
      },
      files(){
        return this.order.sample_files
      },
    }
}
</script>

<style>

</style>