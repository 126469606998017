<template>
  <div class="card">
    <div class="card-body">
      <div class="order-details-body">
        <div class="row">
          <div class="col-12">
            <h4 class="mb-0">Item Purchased: <span class="text-muted"> <a :href="absoluteUrl(`/pricing?get_slug=${product.slug}`)"
              class="cursor-pointer" target="_blank"> {{product.name}} </a> -  {{orderPackage.name}} package</span></h4>
          </div>
          <div class="col-12 col-md-4">
            <h5 class="">Order number: <span class="text-muted">#{{order.number}}  </span></h5>
          </div>
          <div class="col-6 col-md-4">
            <h5 class="">Status: <span class="text-muted text-capitalize">{{order.status}}  </span></h5>
          </div>
          <div class="col-6 col-md-4">
            <h5 class="">Total price: <span class="text-muted text-capitalize"> ${{order.amount | money_format}}  </span></h5>
          </div>
          <div class="col-12">
              <div class="text-muted">
                Buyer:
                <router-link :to="`/users/${order.buyer_id}`" class="cursor-pointer">
                {{order.buyer.first_name }} {{order.buyer.last_name }} (view profile)
                </router-link>
            </div>
          </div>
          <div class="col-12">
            <p class="mb-0">Date: <span class="text-muted">{{order.created_at | date(true)}}  </span></p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <div>
              <feature-list :cart="cart" />
            </div>
            <div v-if="cart.addons && cart.addons.length" class="mt-4">
              <addons />
            </div>
            <div class="mt-4">
              <requirement-list />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Addons from "./components/Addons.vue"
import FeatureList from "./components/FeatureList.vue"
import RequirementList from "./components/RequirementList.vue"

export default {
  components: {
    RequirementList,
    FeatureList,
    Addons
  },
  data() {
    return {
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.cart.package
    },
    product(){
      return this.cart.product
    },
  },
}

</script>

<style type="text/css" scoped>

    sub{font-size: 12px;}

    @media(max-width: 580px){
      .order-details-header{
        margin-bottom: 1.5em;

      }
    }
</style>
