<template>
<div class="conversation-text" :class="float === 'right' ? 'ml-0':'mr-0'">
    <div class="ctext-wrap">
        <p class="mb-2 font-12">{{ message.message }}</p>
        <message-files v-if="message.files && message.files.length" :list="message.files"/>
        <p class="mb-2 pb-2 border-bottom"> <small style='font-size:10px;'>{{ message.created_at | date_ago }}</small></p>
        <div v-if="message.replyer_id" class="mb-2 py-2 text-right">
            <h6 class="font-weight-bold text-inherit font-italic text-right">{{message.who == 'admin'? 'Buyer' : 'You'}} Replied:</h6>
            <p class="mb-2 text-right">{{message.reply}}</p>
            <small style='font-size:10px;'>{{ message.replied_at | date_ago }}</small>
        </div>
        <slot v-else name="footer-action"></slot>
    </div>
</div>
</template>

<script>
import MessageFiles from './MessageFiles';

export default {
    components:{
        MessageFiles
    },
    props:{
        message:{
            type:Object,
            required:true
        },
        float:{
            type: String,
            required:true
        }
    },
    computed: {

    },

};
</script>


<style>


</style>
