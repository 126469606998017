<template>
    <div class="order-messages">
        <div class="card shadow-sm">
            <div class="card-body px-2 py-2 border-bottom">
                <div class="py-1">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h5 class="mb-0">Conversations for Order ID: #{{order.number}}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-2 ">
                <message-window ref="orderLogPSWindow" :messages="messageData">
                    <template v-slot:footer>
                        <div class="mt-2">
                           <b-tabs>
                                <b-tab title="Delivery" active>
                                    <div class="pt-2">
                                        <upload-attachment :isDelivery="true" @scrollMessagesUp="resetForm(); scrollMessageAreaUp() "/>
                                    </div>
                                </b-tab>
                                <b-tab title="Message" >
                                    <div class="pt-2">
                                        <textarea v-model="form.message" placeholder="Write message"
                                        class="form-control" rows="3"></textarea>
                                        <div class="d-flex gap-2 mt-2">
                                            <button @click="modalUploadFiles = true" class="btn btn-light mr-2"><i class="ri-attachment-2"></i></button>
                                            <button @click="sendMessage()"  class="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </template>
                </message-window>
            </div>
        </div>
        <div>
            <b-modal centered v-model="modalUploadFiles" title="Upload Files" 
                header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
                <upload-attachment v-if="modalUploadFiles" @scrollMessagesUp="resetForm(); scrollMessageAreaUp() "/>
            </b-modal>
        </div>
    </div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue'
import MessageWindow from "./components/MessageWindow.vue"
import UploadAttachment from "./components/UploadAttachment.vue"

export default {
	components: {
        MessageWindow,
        UploadAttachment,
        VueDropzone,
	},
	data() {
		return {
            modalUploadFiles:false,
            form:{
                message: ""
            },
		}
	},
	computed: {
        order(){
            return this.$store.state.orderList.order
        },
        messageData(){
            return this.order.messages
        },
	},
	methods: {
        sendMessage() {
            if (!this.form.message) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.form))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/order-messages/${this.$route.params.orderId}/create`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                    this.$store.commit('orderList/ADD_MESSAGE', response.data.data)
                    this.resetForm();
                    this.scrollMessageAreaUp();
                }
            })
        },
        scrollMessageAreaUp(){
            setTimeout(() => {
                this.$refs.orderLogPSWindow.scrollMessageAreaUp()
            },1000)
        },
        resetForm(){
            this.modalUploadFiles = false;
            this.form.message =  "";
            this.status.modal = false;
        },
	},
    mounted(){
        this.scrollMessageAreaUp()
    }

}
</script>

